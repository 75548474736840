import React from 'react'

function Shows () {
  return (
    <div className='flex flex-col justify-center items-center h-screen'>
      <h1 className='text-center mb-4'>
        There are no shows currently booked but check back soon for updates
      </h1>
    </div>
  )
}

export default Shows
