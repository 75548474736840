import React from 'react'

function About () {
  return (
    <div className='text-center md:text-left'>
      <h1 className='flex justify-center mt-12 text-3xl animate-slideIn'>
        Who is Mello?
      </h1>
      <br />
      <p
        className='bg-black flex-auto justify-center break-normal text-lg max-w-lg mx-auto animate-slideIn'
        style={{ maxWidth: '35rem' }}
      >
        Mello Anello is a multi-talented artist who draws inspiration from the
        legendary Prince to create funky, groove-inducing music that is
        impossible to resist. With a unique blend of soulful vocals,
        electrifying guitar riffs, and infectious beats, Mello Anello's music
        will have you dancing and singing along in no time.
        <br />
        <br />
        In addition to his solo work, Mello Anello has also had the opportunity
        to co-write and co-produce on various tracks, showcasing his versatility
        as a musician. One of his songs, "Unbound," even earned the distinction
        of being named the Hottest Record in the World by BBC's Annie Mac,
        cementing Mello Anello's status as an up-and-coming force in the music
        industry.
        <br />
        <br />
        If you're looking for music that is guaranteed to get you moving and
        grooving, look no further than Mello Anello. With his signature funk
        sound and impressive musical talent, Mello Anello is sure to bring the
        party to any track he touches.
      </p>
    </div>
  )
}

export default About
