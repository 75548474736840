import React, { useState } from 'react'
import emailjs from 'emailjs-com'

function ContactForm () {
  const SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID
  const TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID
  const USER_ID = process.env.REACT_APP_EMAILJS_USER_ID
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  })

  const handleInputChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    })
  }

  const handleSubmit = event => {
    event.preventDefault()

    if (!formData.name || !formData.email || !formData.message) {
      alert('Please fill out all fields.')
      return
    }

    emailjs
      .send(SERVICE_ID, TEMPLATE_ID, formData, USER_ID)
      .then(response => {
        console.log('Email sent!', response.status, response.text)
        setFormData({
          name: '',
          email: '',
          message: ''
        })
        alert('Your message was sent successfully!')
      })
      .catch(error => {
        console.log('Email failed to send.', error)
        alert(
          'There was an error sending your message. Please try again later.'
        )
      })
  }

  const handleKeyPress = event => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault()
      handleSubmit(event)
    }
  }

  return (
    <div className='flex justify-center items-center h-screen'>
      <form
        className='w-full max-w-md p-4 bg-gray-200 rounded-lg'
        onSubmit={handleSubmit}
      >
        <label className='block text-gray-700 font-bold mb-2' htmlFor='name'>
          Name:
        </label>
        <input
          className='w-full text-purple-500 bg-white border border-gray-300 rounded-lg py-2 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'
          type='text'
          name='name'
          id='name'
          value={formData.name}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
        />

        <label className='block text-gray-700 font-bold mb-2' htmlFor='email'>
          Email:
        </label>
        <input
          className='w-full text-purple-500 bg-white border border-gray-300 rounded-lg py-2 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'
          type='email'
          name='email'
          id='email'
          value={formData.email}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
        />

        <label className='block text-gray-700 font-bold mb-2' htmlFor='message'>
          Your Message:
        </label>
        <textarea
          className='w-full h-32 resize-none  text-purple-500 bg-white border border-gray-300 rounded-lg py-2 px-4 mb-6 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'
          name='message'
          id='message'
          value={formData.message}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
        />

        <button
          className='bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline w-full'
          type='submit'
        >
          Send Message
        </button>
      </form>
    </div>
  )
}
export default ContactForm
