import { Link } from 'react-router-dom'

function Header () {
  return (
    <div className='text-xl font-bold'>
      <nav>
        <ul className='hidden sm:flex justify-center'>
          <li className='mx-8'>
            <Link to={'/'}>Home</Link>
          </li>
          <li className='mx-8'>
            <Link to={'/about'}>About</Link>
          </li>
          <li className='mx-8'>
            <Link to={'/contact'}>Contact</Link>
          </li>
          <li className='mx-8'>
            <Link to={'/shows'}>Shows</Link>
          </li>
        </ul>
        <ul className='sm:hidden flex flex-col items-center'>
          <li className='my-2'>
            <Link to={'/'}>Home</Link>
          </li>
          <li className='my-2'>
            <Link to={'/about'}>About</Link>
          </li>
          <li className='my-2'>
            <Link to={'/contact'}>Contact</Link>
          </li>
          <li className='my-2'>
            <Link to={'/shows'}>Shows</Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default Header
