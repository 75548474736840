import React from 'react'
import { FaInstagram, FaSpotify, FaApple } from 'react-icons/fa'

function Footer () {
  return (
    <div className='bg-black text-gray-300 py-6 flex flex-col items-center justify-center w-full absolute bottom-0'>
      <div className='flex items-center justify-center mb-4'>
        <a
          href='https://www.instagram.com/whotfismello/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <FaInstagram className='text-purple-800 mr-4' size={24} />
        </a>
        <a
          href='https://open.spotify.com/artist/5EAbfP7ZbYixDcfVAQVYUo?si=Kr4kmFzFSNCsoydPUMEaJQ'
          target='_blank'
          rel='noopener noreferrer'
        >
          <FaSpotify className='text-purple-800 mr-4' size={24} />
        </a>
        <a
          href='https://music.apple.com/us/artist/mello-anello/1062638821'
          target='_blank'
          rel='noopener noreferrer'
        >
          <FaApple className='text-purple-800' size={24} />
        </a>
      </div>
      <p className='text-sm'>
        &copy; {new Date().getFullYear()} Mello Anello. All rights reserved.
      </p>
    </div>
  )
}

export default Footer
