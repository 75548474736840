import React, { useRef, useEffect } from 'react'
import * as THREE from 'three'

export default function ThreeScene () {
  const sceneRef = useRef(null)

  useEffect(() => {
    const scene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    )

    const renderer = new THREE.WebGLRenderer({ antialias: true })
    renderer.setSize(window.innerWidth, window.innerHeight)
    renderer.setClearColor(0x000000) // Set background color to black
    sceneRef.current.appendChild(renderer.domElement)

    const geometry = new THREE.BoxGeometry(2, 2, 2) // Increase the size of the cube
    const material = new THREE.MeshBasicMaterial({ color: 0x6a0dad }) // Set color to royal purple
    const cube = new THREE.Mesh(geometry, material)
    scene.add(cube)

    camera.position.z = 5

    const animate = () => {
      requestAnimationFrame(animate)
      cube.rotation.x += 0.01
      cube.rotation.y += 0.01
      renderer.render(scene, camera)
    }

    animate()

    return () => {
      // Clean up resources
      renderer.dispose()
      material.dispose()
      geometry.dispose()
    }
  }, [])

  return (
    <div className='three-scene-container'>
      <div ref={sceneRef} />
    </div>
  )
}
