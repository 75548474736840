import React from 'react'
import '../pages/css/home.css'

function Home () {
  return (
    <div className='text-center md:text-left'>
      <h1 className='flex justify-center mt-12 text-3xl animate-slideIn'>
        Welcome to Mello Anello's Site. Make yourself at home.
      </h1>
    </div>
  )
}

export default Home
