import { Route, Routes } from 'react-router-dom'
import './App.css'
import Header from './components/Header'
import ThreeScene from './components/ThreeScene'
import About from './pages/About'
import Contact from './pages/Contact'
import Shows from './pages/Shows'
import Home from './pages/Home'
import Footer from './components/Footer'
export default function App () {
  return (
    <div>
      <ThreeScene />
      <div className='content'>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/shows' element={<Shows />} />
        </Routes>
      </div>
      <Footer />
    </div>
  )
}
