import ContactForm from '../components/ContactForm'

function Contact () {
  return (
    <div>
      <ContactForm />
    </div>
  )
}

export default Contact
